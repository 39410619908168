export default {
  // Common
  'common.tips.deliveryHome':
    'Het is mogelijk om dit product thuis te laten bezorgen.',
  'common.tips.pickUpStore':
    'Het is mogelijk om dit product af te halen in een winkel.',

  'common.store.noLocationAuth':
    'Je hebt het delen van je huidige locatie geweigerd. Schakel deze toestemming in je browserinstellingen in',

  'common.text.terms': 'Terms',
  'common.text.newMsg':
    'Ik wil graag op de hoogte gehouden worden van de laatste nieuwtjes',
  'common.text.agreement':
    'Ik ga akkoord met de <a href="{link}" target="_blank" class="text-pfm-black-400 underline hover:no-underline">algemene voorwaarden*</a>',
  'common.text.login': 'Inloggen',
  'common.text.register': 'Registreren',
  'common.text.language': 'Taal',

  'common.text.newsletter': 'Nieuwsbrief',
  'common.text.newsletterSubscription':
    'Wil je altijd op de hoogte blijven van onze nieuwtjes en acties? Maak eenvoudig een account aan en schrijf je dan in voor onze nieuwsbrief!',
  'common.text.advice': 'Advies nodig?',
  'common.text.mail': 'Mail ons',
  'common.text.chat': 'Chat met ons',
  'common.text.question': 'Beauty vragen?',
  'common.text.contact': 'Bel ons',
  'common.text.securePayment': 'Veilig betalen',
  'common.text.searchPlaceholder': 'Wat zoek je?',
  'common.text.loginRegister': 'Inloggen/registreren',
  'common.text.welcome': 'Welkom',
  'common.text.logout': 'Uitloggen',
  'common.text.product': 'product',
  'common.text.products': 'producten',
  'common.text.continueShopping': 'Verder winkelen',
  'common.text.inclVAT': 'Incl. BTW',
  'common.text.previous': 'Vorige',
  'common.text.next': 'Volgende',
  'common.text.nextStep': 'Volgende stap',
  'common.text.promoCode': 'KORTINGSCODE',
  'common.text.filterChosen': 'Je gekozen filters:',
  'common.text.price': 'Prijs',
  'common.text.moreInfoShort': 'meer info',
  'common.text.moreInfo': 'Meer informatie',
  'common.text.about': 'Over',
  'common.text.loading': 'Bezig met laden...',
  'common.text.id': 'id',
  'common.text.color': 'kleur',
  'common.text.toOrder': 'Bestellen',
  'common.text.viewMore': 'Bekijk meer',
  'common.text.viewAll': 'Bekijk alle gegevens',
  'common.text.changeData': '> wijzig gegevens',
  'common.text.unknown': 'Onbekend',
  'common.text.loadMore': 'Laad meer',
  'common.text.click': 'Klik',
  'common.text.here': 'hier',
  'common.text.share': 'Deel op {name}',
  'common.text.all': 'alle',

  'menu.brand': 'Merken',
  'menu.brand.popular': 'Populaire merken',
  'menu.brand.new': 'Nieuw merken',

  'text.cookie.preferences': 'VOORKEUREN',
  'text.cookie.accept': 'AKKOORD',
  'text.cookie.refuse': 'WEIGEREN',
  'text.cookie.introduction': 'COOKIES OP BEAUTY-X.NL',
  'text.cookie.content': `Cookies helpen ons om je relevante advertenties en producten te tonen, maar ook om onze website te optimaliseren. Wanneer jij daar toestemming voor geeft, verzamelen wij en onze partners met de cookies informatie over je internetgedrag. Op basis daarvan tonen we je producten en advertenties gebaseerd op je interesses en profiel. <br /><br />Wij gebruiken cookies voor social media, gepersonaliseerde advertenties en voor analyses of statistieken van website gebruik. Via "Voorkeuren' kun jij per categorie je persoonlijke voorkeuren aanpassen. Je kunt ons ook in een keer toestemming geven voor alle categorieen door op 'Akkoord' te klikken. Wanneer je cookies wilt weigeren, worden alleen noodzakelijke cookies geplaatst.`,

  'cookie.preferences.essential': 'Noodzakelijke cookies',
  'cookie.preferences.essentialExplain':
    'Noodzakelijke cookies zorgen ervoor dat de website naar behoren functioneert. Zoals het onthouden van de producten in je winkelmandje of het tonen van je eerder bekeken producten. Voor deze cookies is geen toestemming nodig.',
  'cookie.preferences.social': 'Social media cookies',
  'cookie.preferences.socialExplain':
    'Social media cookies maken de functionaliteiten van verschillende sociale media zoals Facebook of Twitter mogelijk. Deze cookies worden door deze platforms zelf geplaatst, bijvoorbeeld op het moment dat je op onze website een social media button aanklikt. Voor de cookies die deze social media platforms plaatsen en de data die zij hiermee verzamelen, verwijzen we naar de cookie verklaringen van het betreffende platform.',
  'cookie.preferences.advertising': 'Advertentiecookies',
  'cookie.preferences.advertisingExplain':
    'Met advertentiecookies kunnen wij je relevante advertenties tonen. Hiermee proberen we een beter beeld van jouw interesses en voorkeuren te krijgen, waar we onze communicatie en gepersonaliseerde advertenties op aanpassen.',
  'cookie.preferences.statistics': 'Statistische cookies',
  'cookie.preferences.statisticsExplain':
    'Statistische cookies worden gebruikt om te analyseren hoe de website wordt gebruikt. Bijvoorbeeld hoe vaak de website wordt bezocht, op welke pagina bezoekers binnenkomen of op welke pagina’s het meest wordt geklikt. Op basis van deze informatie proberen we de website voor jou zo gebruiksvriendelijk mogelijk te maken. Voor deze analyse maken we gebruik van Google cookies waarbij Google persoonsgegevens van jou kan verwerken.',
  'cookie.preferences.settingTip':
    "Pas de cookies aan op jouw persoonlijke voorkeuren. Door op 'Mijn voorkeur bevestigen' te klikken ga je akkoord met ons cookiebeleid. Door op 'Alles weigeren' te klikken worden alleen de noodzakelijke cookies geplaatst.",
  'cookie.preferences.rejectAll': 'Alles weigeren',
  'cookie.preferences.confirmPreference': 'Mijn voorkeuren bevestigen',

  'text.noProduct': 'Geen product',
  'text.free': 'Gratis',
  'text.total': 'Totaal',
  'text.date': 'Datum',
  'text.amount': 'Bedrag',
  'text.voucher.activeVouchers': 'Mijn actieve vouchers',
  'text.voucher.productVouchers': 'Mijn beschikbare productvouchers',
  'text.voucher.paymentVouchers': 'Mijn beschikbare betaalvouchers',
  'text.voucher.add': 'Adressen',
  'text.sort.MinPrice:asc': 'Prijs: Laag naar hoog',
  'text.sort.MinPrice:desc': 'Prijs: Hoog naar laag',
  'text.sort.Title:asc': 'Alfabetisch: A tot Z',
  'text.sort.Title:desc': 'Alfabetisch: Z tot A',
  'text.sort.RatingAverage:desc': 'Beoordeling',
  'text.sort.CreationDate:desc': 'Nieuwste',
  'text.sort.WeightingFactor:desc': 'Relevantie',
  'text.readMore': 'Lees verder',
  'text.redirect': 'Omleiden...',
  'text.mailto.subject': 'Bekijk deze pagina die super tof is',
  'text.mailto.body': 'Bekijk nu dit onwijs toffe {name}',
  'text.register.loyaltyCustomer':
    "Ja, ik wil sparen voor kortingen en extra's met beauty-x",
  'text.beautyX': 'BEAUTY X',
  'text.unit.perPageItems': '{items} ARTIKELEN',
  'text.close': 'sluiten',
  'text.about': 'Over',
  'text.perPage': 'P.',
  'text.reviews': 'reviews',
  'text.review': 'review',
  'text.stock.noStock': 'Geen voorraad',
  'text.stock.limitedStock': 'Beperkte voorraad',
  'text.stock.enoughStock': 'Ruime voorraad',
  'text.stock.onlineStock': 'Online op voorraad',
  'text.checkout.noStock': 'Niet op voorraad',
  'text.checkout.enoughStock': 'Op voorraad',
  'text.checkout.deliveryTime': 'Ma-vr voor 22:00 besteld, morgen bezorgd',
  'text.checkout.postageCost': 'Berekend in de volgende stap',
  'text.gift.free': 'gratis cadeauverpakking',
  'text.benefits': 'JOUW VOORDELEN',
  'text.benefits.sampleProduct': 'Gratis samples naar keuze',
  'text.benefits.24hour': 'Ma-Vr voor 22:00 besteld, morgen bezorgd',
  'text.benefits.freeDelivery': 'Gratis verzending vanaf €30',
  'text.benefits.register':
    'Registreer nu en profiteer o.a. van de volgende voordelen:',
  'text.order.guest':
    ' Je kunt ook bij ons bestellen zonder een account aan te maken',
  'text.allCategories': 'Alle categorieën',
  'text.viewAllPosts': 'Bekijk alle berichten',
  'text.myAccount': 'Mijn account',
  'text.search': 'zoeken',
  'text.filter': 'Filteren',

  'description.amount': 'bedrag dat op de kaart nog staat',

  'tips.shipping': ' VOOR 23.59 UUR BESTELD IS MORGEN IN HUIS',
  'tips.gift.text': 'Welke tekst wil je op de cadeauverpakking zetten?',
  'tips.attention':
    'Opgelet: Helaas kunnen we een terugname van make-up producten niet aanvaarden omwille van hygiënische redenen.',

  'title.cart.shoppingCart': 'Mijn winkelmandje',
  'title.store.search': 'Jouw dichtsbijzijnde winkel',
  'title.gift': 'Cadeau ideeën',
  'title.modal.pickSample': 'KIES {quantity} GRATIS SAMPLES',
  'title.checkout': 'Kassa',
  'title.checkout.overview.geneva': 'Gegevens',
  'title.checkout.overview.payment': 'BETAALWIJZE',
  'title.checkout.overview.delivery': 'Levering',
  'title.checkout.overview.pickupAddress': 'Afhaaladres',
  'title.checkout.overview.deliveryAddress': 'Bezorgadres',
  'title.checkout.promoCode': 'Kortingscode / coupon',
  'title.newAccount': 'NIEUWE KLANT',
  'title.order.guest': 'BESTEL ZONDER ACCOUNT',

  'title.checkout.success.delivery': 'Bezorgadres',
  'title.checkout.success.pickup': 'Ophalen bij BeautyX-winkel',
  'title.checkout.success.delivery.address': 'BEZORGADRES',

  'title.news': 'Nieuws',
  'title.themes': "Thema's",
  'title.highlight': 'Uitgelicht',

  'title.brand': 'Merk',

  // placeholder
  'placeholder.selectOne': 'Selecteer er een alstublieft',

  // message
  'message.promotion.invalid':
    'Deze actiecode is ongeldig of voldoet nog niet aan de actievoorwaarden.',
  'message.promotion.repeated':
    'De kortingscode is al succesvol geactiveerd. Het is niet mogelijk om dezelfde kortingscode meerdere malen te activeren.',
  'message.promotion.code.error': 'Er is geen geldige actiecode ingevuld',
  'message.register.fail':
    'Er is al een klant bekend op het opgegeven emailadres.',

  // button
  'button.seeMoreGift': 'BEKIJK ALLE CADEAU IDEEËN HIER >',
  'button.createAccount': 'Account aanmaken',
  'button.register': 'Inschrijven',
  'button.promoCode': 'INWISSELEN',
  'button.order.noAccount': 'zonder account',

  'button.showLessFilters': 'Toon Minder Filters',
  'button.showMoreFilters': 'Toon Meer Filters',
  'button.shutDown': 'Afsluiten',
  'button.showLess': 'Toon Minder',
  'button.showMore': 'Toon Meer',

  'common.button.checkout': 'Naar de kassa',
  'common.button.pay': 'Betalen',
  'common.button.toCart': 'Naar mijn winkelwagen',
  'common.button.removeFilter': 'Verwijder alle filters',
  'common.button.viewAllOrder': 'Bekijk alle bestellingen',
  'common.button.addAddress': 'Adres toevoegen',

  // Login
  'login.title': 'Inloggen / Registreren',
  'login.existing': 'Bestaande klant',
  'login.forgotPassword': 'Wachtwoord vergeten?',
  'login.oldPassword': 'Oud wachtwoord',
  'login.newPassword': 'Nieuw wachtwoord',
  'login.confirmPassword': 'Herhaal nieuw wachtwoord',
  'login.confirmPasswordNotice':
    'Het wachtwoord moet minimaal 8 karakters en minimaal 1 cijfer bevatten',
  'register.newAccount': 'Nieuw bij BEAUTY X?',

  // user information
  'userInfo.email': 'E-mailadres',
  'userInfo.password': 'Wachtwoord',
  'userInfo.confirmPassword': 'Wachtwoord bevestigen',
  'userInfo.gender': 'Aanhef',
  'userInfo.gender.Male': 'Man',
  'userInfo.gender.Female': 'Vrouw',
  'userInfo.gender.title.Male': 'Dhr.',
  'userInfo.gender.title.Female': 'Mevr.',
  'userInfo.firstName': 'Voornaam',
  'userInfo.middleName': 'Tussenvoegsel',
  'userInfo.lastName': 'Achternaam',
  'userInfo.phoneNumber': 'Telefoonnummer',
  'userInfo.mobile': 'Mobiel nummer',
  'userInfo.address.land': 'Land',
  'userInfo.address.SelectCountry': 'Selecteer land',
  'userInfo.address.postalCode': 'Postcode',
  'userInfo.address.houseNumber': 'Huisnummer',
  'userInfo.address.houseNumberExtension': 'Toevoeging',
  'userInfo.address.street': 'Straat',
  'userInfo.address.city': 'Woonplaats',

  // Checkout
  'checkout.steps.information': 'GEGEVENS',
  'checkout.steps.delivery': 'BEZORGING',
  'checkout.steps.payment': 'BETAALWIJZE',
  'checkout.steps.overview': 'OVERZICHT',
  'checkout.steps.success': 'AFREKENEN',

  'checkout.overview.remarkPlaceholder':
    'Geef jouw keuze van 3 stalen bij aankoop vanaf €20: Geef jouw voorkeur in dames & heren geuren of verzorging. Keuze stalen zijn onder voorbehoud, gelieve ook altijd een reserve keuze toe te voegen. Jo Malone London, La Mer en Make-Up samples zijn helaas niet mogelijk.',
  'checkout.shoppingBag': 'WINKELMANDJE',
  'checkout.postageCosts': 'Verzendkosten',
  'checkout.delivery': 'BEZORGING',
  'checkout.personalInformation': 'Contactgegevens',
  'checkout.tips.requireFields':
    'De velden met een * hebben wij nodig om je bestelling goed te kunnen verwerken.',
  'checkout.noAddressFindWarning':
    'Geen adres gevonden op basis van {postalCode} / {houseNumber}',
  'checkout.overview.shoppingBag': 'OVERZICHT VAN JE BESTELLING',
  'checkout.overview.checkoutOrder': 'Bestelling afrekenen',
  'checkout.overview.billingAddress': 'FACTUUR ADRES',
  'checkout.overview.changeDeliveryMethod': '> wijzig leveringsmethode',
  'checkout.overview.changePaymentMethod': '> wijzig betaalwijze',
  'checkout.paymentMethod': 'BETAALMETHODE',
  'checkout.success.breadcrumb': 'Bedankt - Order {orderNumber}',
  'checkout.success.title': 'BEDANKT VOOR JE AANKOOP',
  'checkout.success.packageDealWith': 'Beste {name},',
  'checkout.success.orderConfirm':
    '<br /><p>We hebben je betaling in goede orde ontvangen. Op dit moment zijn we al aan het werk om je product(en) te verzamelen en in te pakken.</p><br /><p>In je mail vind je een bevestiging van je bestelling. Wanneer we je pakket verstuurd hebben ontvang je een verzendbevestiging met Track & Trace-link om je pakket te volgen.</p><br /><p>Je ontvangt een order bevestiging op: <strong>{email}</strong> met <strong>ordernummer {orderNumber}</strong>.</p><br /><p>Hartelijk bedankt voor je aankoop en we zien je graag terug.</p><br /><p>Beauty X</p>',
  'checkout.success.social': 'Like ons op social media',
  'checkout.success.socialFacebook': 'Facebook',
  'checkout.success.socialInstagram': 'Instagram',
  'checkout.success.socialPinterest': 'Pinterest',
  'checkout.step.facts': 'Gegevens',
  'checkout.step.overview': 'Overzicht',
  'checkout.step.pay': 'Betalen',
  'checkout.step.confirmation': 'Bevestiging',
  'checkout.warning.autoFix':
    'Als u op "{button}" klikt, worden ze ook automatisch verwijderd.',

  // delivery
  'delivery.personal': 'persoonlijke account',
  'delivery.address.manager': '* Adressen zijn te beheren in je',
  'delivery.address.notPossible':
    'Thuisbezorging is niet mogelijk in alle landen.',

  // pickup
  'pickup.personal': 'persoonlijke account',
  'pickup.store.manager': 'Winkels zijn te beheren in je',

  // Cart
  'cart.Total': 'Totaal {quantity} product(en)',
  'cart.viewShoppingBag': 'Bekijk hele winkelwagen',
  'cart.changeShoppingBag': 'Bekijk/wijzig mijn winkelwagen',
  'cart.subTotal': 'Subtotaal',
  'cart.shippingCost': 'Verzendkosten',
  'cart.discount': 'Korting',
  'cart.totalPrice': 'Totaalbedrag',
  'cart.delivery.options': 'Leveringsmogelijkheden',
  'cart.delivery.options.PickupMyStore': 'Ophalen bij mijn BeautyX',
  'cart.delivery.options.PickupOtherStore': 'Ophalen bij een Beautyx winkel',
  'cart.delivery.options.DeliveryMyAddress': 'Thuisbezorging',
  'cart.delivery.options.DeliveryAlternativeAddress':
    'Thuisbezorging (alternatief adres)',
  'cart.delivery.address.DeliveryMyAddress': 'Standaard adres',
  'cart.delivery.address.DeliveryAlternativeAddress': 'Alternatief adres',
  'cart.noProducts': 'Er staan geen producten in je winkelwagen.',
  'cart.addToCartMobile': 'Toegevoegd!',
  'cart.addToCartDesktop': 'Toegevoegd aan je winkelwagen',
  'cart.inShoppingBag': 'In winkelwagen',

  'cart.warning.overStock':
    'Sommige producten zijn momenteel niet op voorraad. Verlaag de hoeveelheid of verwijder deze producten.',
  'cart.warning.autoFix': 'om ze automatisch te verwijderen.',

  'cart.line.giftwrapping': 'Cadeauverpakking',
  'cart.line.giftwrappingWithText': 'Cadeauverpakking Cadeautekst',

  // Search Panel
  'searchPanel.brand': 'Merken',
  'searchPanel.suggestions': 'Suggesties',
  'searchPanel.categories': 'Categorieën',
  'searchPanel.searchResult': 'ZOEKRESULTATEN',

  // Brand
  'brand.title': 'Merken',
  'brand.noResult': 'Nog geen merk',

  // error
  'error.message.notFound': '{name} is niet gevonden.',
  'error.message.product': 'Dit product',
  'error.message.oops': 'Oops!',
  'error.message.lost': 'Ben je verdwaald?',
  'error.message.notFoundPage':
    'We kunnen de fantastische pagina die je zoekt niet vinden.',
  'error.message.findWayBack': 'LAAT ONS JE HELPEN DE WEG TERUG TE VINDEN.',
  'error.message.backHome': 'Ga naar de homepagina',

  // Product
  'product.connections.Size': 'Andere inhoud',
  'product.connections.Color': 'Andere inhoud color',
  'product.connections.Variant': 'Andere inhoud variant',
  'product.connections.Interesting': 'Interessant',

  'product.label.limitedlabel': 'limited',
  'product.label.cleanbeautylabel': 'clean beauty',
  'product.label.nieuwlabel': 'new',
  'product.label.bestsellerlabel': 'best seller',
  'product.label.tryandbuy': 'try & buy',

  'product.text.unit': 'X',
  'product.text.ingredients': 'Ingrediënten',
  'product.text.description': 'Productinformatie',
  'product.text.barcode': 'Artikel Nr.',
  'product.text.usageDescription': 'Gebruiksaanwijziging',
  'product.text.introduction': 'Productbeschrijving',
  'product.text.relatedProducts': 'Gerelateerde producten',
  'product.text.checkStoreStock': 'Bekijk de voorraad van onze winkels',
  'product.text.recentlyViewed': 'Recent bekeken',
  'product.text.summaryTitle': 'Deze wordt het',
  'product.text.reviews.title': 'Reviews en beoordelingen',

  'product.availability.NotOrderable':
    'Dit product is niet voorradig maar kunnen we voor je bestellen, de levertijd bedraagt 2 tot 4 weken.',
  'product.availability.InStock': 'Ma-vr voor 22:00 besteld, morgen bezorgd',
  'product.availability.NotInStock':
    'Dit product is niet voorradig maar kunnen we voor je bestellen, de levertijd bedraagt 2 tot 4 weken.',

  'product.availability.OutOfMaxInCart':
    'Van dit product mogen er maximaal {maxInCart} besteld worden per bestelling',

  'product.facet.BrandTitle': 'Merk',
  'product.facet.WebNodeIds': 'Categorie',
  'product.facet.ExternalLine': 'Collectie',
  'product.facet.GrossPrice': 'Prijs',
  'product.facet.ExternalFragFamily': 'Geurfamilie',
  'product.facet.ExternalContent': 'Inhoud',
  'product.facet.ExternalProductSkinType': 'Huidtype',
  'product.facet.ExternalConcern': 'Huidzorg',
  'product.facet.ExternalFormulations': 'Textuur',
  'product.facet.ExternalCoverage': 'Dekking',
  'product.facet.ExternalFinish': 'Afwerking',
  'product.facet.ExternalSunProtection': 'Zonnebescherming',
  'product.facet.ExternalBenefits': 'Mascara Effect',
  'product.facet.ExternalHairConcern': 'Haarbehoefte',

  // Reviews
  'reviews.noReview': 'Nog geen reviews geschreven',
  'reviews.write': 'Schrijf de eerste review',
  'reviews.writeAReview': 'Schrijf een review',
  'reviews.about': 'Reviews over',
  'reviews.title': 'REVIEW SCHRIJVEN',
  'reviews.rating': 'Beoordeling',
  'reviews.experience': 'Je ervaring',
  'reviews.required': 'required',
  'reviews.button.send': 'Review verzenden',
  'reviews.leaveReview': 'Geef jouw beoordeling',
  'reviews.averageRating':
    'Gemiddelde beoordeling op basis van <span class="font-bold">{reviewsCount} reviews</span>',
  'reviews.firstReivew':
    'We hebben nog niet voldoende reviews ontvangen voor dit product. <span class="font-bold cursor-pointer underline">Beoordeel dit product</span> als eerste.',
  'reviews.rating.star': 'ster',
  'reviews.rating.stars': 'sterren',
  'reviews.modal.title': 'LAAT JE REVIEW ACHTER VOOR:',
  'reviews.modal.howManyStars': 'Hoeveel sterren geef je dit product?',
  'reviews.modal.whatExperience': 'Wat is jouw ervaring met het product',
  'reviews.modal.writeReviewContent': 'Schrijf je review...',
  'reviews.modal.loginTips': 'log in',
  'reviews.modal.publishTips': 'om je review te plaatsen',
  'reviews.modal.submitButton': 'PLAATS JOUW REVIEW',

  // Store
  'store.stores': 'winkels',
  'store.branches': 'Vestigingen',
  'store.closingTime': 'Vandaag open tot {closingTime}',
  'store.text.open': 'Nu open',
  'store.text.at': 'Na',
  'store.text.close': 'Gesloten',
  'store.text.extraOpeningDays': 'Extra openingsdagen',
  'store.week.sunday': 'zondag',
  'store.week.monday': 'maandag',
  'store.week.tuesday': 'dinsdag',
  'store.week.wednesday': 'woensdag',
  'store.week.thursday': 'donderdag',
  'store.week.friday': 'vrijdag',
  'store.week.saturday': 'zaterdag',
  'page.store.title.contact': 'contact- en adresgegevens',
  'page.store.title.openHours': 'openingstijden',
  'link.gotoGoogleStore': 'plan een route',

  // Breadcrumbs
  'breadcrumbs.writeReview': 'Schrijf beoordeling',
  'breadcrumbs.infrared': 'Infrared {name}',
  'breadcrumb.account.personal': 'Persoonlijke gegevens wijzigen',
  'breadcrumb.account.selectOtherStore': 'Mijn winkel wijzigen',
  'breadcrumb.account.changeAddress': 'Adresgegevens wijzigen',

  // Account
  'account.menu.accountOverview': 'Account overzicht',
  'account.menu.myDetails': 'Mijn gegevens',
  'account.menu.orderStatus': 'Orderstatus',
  'account.menu.myPurchases': 'Mijn aankopen',
  'account.menu.myWishlist': 'Mijn verlanglijst',
  'account.menu.myLoyaltyPoints': 'Mijn spaarpunten',

  'account.title.accountOverview': 'Accountoverzicht',
  'account.title.myDetails': 'Mijn gegevens',
  'account.title.orderStatus': 'Mijn bestellingen',
  'account.title.myPurchases': 'MIJN AANKOPEN',
  'account.title.myWishlist': 'Mijn verlanglijst',
  'title.myLoyaltyPoints': 'Mijn spaarpunten',
  'title.myPaymentVouchers': 'Mijn betaal-vouchers',
  'title.myProductVouchers': 'Mijn product-vouchers',
  'account.title.newsletter': 'Nieuwsbrief instellingen',
  'account.title.selectOtherStore': 'Kies een andere winkel',
  'account.title.invoiceAddress': 'Factuur adres',
  'account.title.deliveryAddress': 'Wijzig adressen',

  'account.myProfile': 'mijn profiel',
  'account.myProfile.lastOrder': 'Mijn laatste bestelling',
  'account.title.myDetail': 'Mijn gegevens',
  'account.title.personalInfo': 'Persoonlijke gegevens',
  'account.title.address': 'Adresgegevens',

  'account.tips.hasProducts': 'Er zitten {count} producten in je winkelwagen.',
  'account.tips.noProducts': 'Er zitten geen producten in je winkelwagen.',
  'account.tips.points': 'Je hebt {points} punten gespaard bij beauty-x.',
  'account.tips.noOrder': 'U heeft nog geen bestelling bij ons gedaan.',
  'account.tips.noSelectedStore': 'Je hebt geen winkel geselecteerd.',
  'account.tips.receiveNewsletter': 'Je ontvangt de nieuwsbrief.',
  'account.tips.unsubscribesNewsletter': 'Je ontvangt geen nieuwsbrief.',

  'account.myDetail.personal': 'Persoonlijke gegevens',
  'account.myDetail.credentials': 'Inloggegevens',
  'account.myDetail.email': 'E-mail',
  'account.myDetail.deliveryAddress': 'Standaard bezorgadres',
  'account.myDetail.deliveryAddress.residentialOrBusinessAddress':
    'Woon- of Vestigingsadres',
  'account.myDetail.deliveryAddress.otherAddresses': 'Overige adressen',
  'account.myDetail.deliveryAddress.edit': 'Pas adres aan',
  'account.myDetail.billingAddress': 'Standaard factuuradres',
  'account.myDetail.myShop': 'Mijn winkel',
  'account.myDetail.newsletter': 'Nieuwsbrief instellingen',
  'account.change.personal': 'Wijzig persoonlijke gegevens',
  'account.change.password': 'Wijzig inloggegevens',
  'account.change.deliveryAddress': 'Bezorgadressen beheren',
  'account.change.billingAddress': 'Factuuradres wijzigen',
  'account.change.myShop': 'Mijn winkel wijzigen',
  'account.change.emailSetting': 'Wijzig e-mail instellingen',
  'account.myLoyaltyPoints.description': 'Beschrijving',
  'account.myLoyaltyPoints.value': 'Waarde',
  'account.myLoyaltyPoints.loyaltyNumber': 'Aantal spaarpunten:',
  'account.myLoyaltyPoints.loyaltyValue': 'Waarde spaarpunten:',

  'account.wishlist.emptyWishlist':
    'Er staan geen producten in je verlanglijst.',
  'account.wishlist.clearWishlist': 'Alle producten verwijderen',
  'account.wishlist.addWishlistToCart': 'Alle producten in winkelwagen',
  'account.wishlist.noStock': 'Dit product is momenteel niet op voorraad. ',

  'account.newsletter.label': 'Nieuwsbrief',
  'account.newsletter.notAccept': 'Nee, ik wil geen nieuwsbrief ontvangen',
  'account.newsletter.isOptIn':
    'Ja, ik wil via de e-mail op de hoogte blijven van de laatste nieuwtjes',

  'account.purchase.details': 'Details',
  'account.purchase.deliveryAddress': 'Verzendadres:',
  'account.purchase.pickupStore': 'Ophalen bij:',
  'account.purchase.billingAddress': 'FACTUUR ADRES:',
  'account.purchase.total': 'Totaal',
  'account.purchase.status': 'Status',
  'account.purchase.status.Cancelled': 'Geannuleerd',
  'account.purchase.status.Open': 'Open',
  'account.purchase.status.Unknown': 'In verwerking',
  'account.purchase.status.Processed': 'Verwerkt',
  'text.orderNumber': 'Ordernummer',
  'account.purchase.delivery': 'Levering:',
  'account.purchase.paymentMethod': 'Betaalmethode:',

  'account.transaction.buyFrom': 'Gekocht bij',
  'account.transaction.noTransactions':
    'Je hebt nog niets gekocht in één van onze winkels.',

  'account.save': 'Opslaan',
  'account.backToOverview': 'Terug naar overzicht',

  'account.changePasswordSuccess': 'Wachtwoord succesvol gewijzigd!',
  'account.changePasswordFailed': 'Veranderen van wachtwoord mislukt!',
  'account.saveSuccessfully': 'Succesvol opgeslagen',
  'account.saveFailed': 'Opslaan mislukt',
  'text.noAvailableVouchers': 'Er zijn geen vouchers beschikbaar',

  // resetPassword
  'title.forgotPassword': 'Wachtwoord vergeten?',
  'title.forgotPassword.success': 'JE WACHTWOORD VERGETEN',
  'text.nav.forgotPassword': 'Nieuw wachtwoord opvragen',
  'description.forgotPassword':
    'Vul hieronder je e-mailadres in en je ontvangt binnen enkele minuten een link waarmee je een nieuw wachtwoord kunt instellen.',
  'button.forgotPassword': 'Reset wachtwoord',
  'message.forgotPassword.sendSuccess':
    'Er is een e-mail verstuurd naar {email} met hierin een link waarmee je een nieuw wachtwoord kunt instellen',

  // sample products
  'sample.select': 'VOEG GRATIS SAMPLES TOE',
}
